import 'antd/dist/antd.css';
// import './App.css';
import axios from 'axios';
import { Statistic, Card, Row, Col, Typography, Progress } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { Layout } from 'antd';
import bitcoin from '../assets/bitcoin.svg';
// import district0x from './assets/district0x.svg';
import cardano from '../assets/cardano.svg';
import dogecoin from '../assets/dogecoin.svg';
import bittorrent from '../assets/bittorrent.svg';
import mintysLogo from '../assets/mintysLogo.svg';
import peepsLogo from '../assets/peepsLogo.jpeg';
import shibLogo from '../assets/shib.svg';
import stellar from '../assets/stellar.svg';
import bezogeImage from '../assets/bezogeImage.png';
import quidImage from '../assets/quidImage.png';
import { makeStyles } from '@material-ui/styles';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { ChainId, Token, WETH, Fetcher, Route } from '@uniswap/sdk';

const useStyles = makeStyles({
  text: {
    // color: '#ffffff !important',
    fontSize: '14px !important',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
  },
  ptext: {
    fontSize: '12px !important',
    // fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
  },
  ctext: {
    fontSize: '14px !important',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
    marginLeft: 8,
  },
  cardTitle: {
    display: 'flex',
    alignContent: 'center',
  },
});

function Dashboard() {
  const classes = useStyles();
  const { Title, Text, Paragraph } = Typography;
  const [dnt, setDnt] = useState({});
  const [btc, setBtc] = useState({});
  const [doge, setDoge] = useState({});
  const [ada, setAda] = useState({});
  const [xlm, setXlm] = useState({});
  const [shib, setShib] = useState({});
  const [peeps, setPeeps] = useState({});
  const [mintys, setMintys] = useState({});
  const [bezoge, setBezoge] = useState({});
  const [quid, setQuid] = useState({});
  const { Countdown } = Statistic;
  const { Content } = Layout;
  const [percent, setPercent] = useState(5);
  const [seconds, setSeconds] = useState(5);
  const gridStyle = {
    width: '33.3%',
    textAlign: 'center',
  };

  const chainId = ChainId.MAINNET;
  const tokenAddress = '0x3Ea50B7Ef6a7eaf7E966E2cb72b519C16557497c'; // must be checksummed
  const decimals = 18;
  const DAI = new Token(chainId, tokenAddress, decimals);
  const USDC = new Token(
    ChainId.MAINNET,
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    6
  );

  const secPercent = {
    0: 100,
    1: 80,
    2: 60,
    3: 40,
    4: 20,
    5: 0,
  };

  const MINUTE_MS = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      callApi();
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, []);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
      setPercent((seconds) => secPercent[seconds]);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  const callApi = async () => {
    axios
      .all([
        axios.get('https://api.binance.com/api/v3/ticker/price?symbol=ADAUSDC'),
        axios.get(
          'https://api.binance.com/api/v3/ticker/price?symbol=SHIBUSDT'
        ),
        axios.get('http://avinashpapineni.com/bezoge'),
        axios.get('http://avinashpapineni.com/quid'),
        axios.get('http://avinashpapineni.com/peeps'),
        axios.get('http://avinashpapineni.com/mintys'),
      ])
      .then(
        axios.spread((data1, data2, data3, data4, data5, data6) => {
          setAda({
            price: data1.data.price,
            //Old: 13185 New: 11785
            //Old: 11785 New: 7785
            balance: 626.5 * data1.data.price,
          });
          setShib({
            price: data2.data.price,
            //Old: 184765404 New: 255694359
            //Old: 255694359 New: 397270366
            balance: 228209593 * data2.data.price,
          });
          setBezoge({ price: data3.data.price, balance: data3.data.balance });
          setQuid({ price: data4.data.price, balance: data4.data.balance });
          setPeeps({ price: data5.data.price, balance: data5.data.balance });
          setMintys({ price: data6.data.price, balance: data6.data.balance });
        })
      );
    setSeconds(5);
  };

  const renderDifference = (amount) => {
    if (amount < 0) {
      return (
        <Text level="4" style={{ color: '#FA672C' }}>
          <ArrowDownOutlined /> ${amount.toFixed(2).substring(1)}
        </Text>
      );
    } else {
      return (
        <Text level="4" style={{ color: '#2DAE34' }}>
          <ArrowUpOutlined /> ${amount.toFixed(2)}
        </Text>
      );
    }
  };

  return (
    <Layout style={{ width: '100%' }}>
      <Content
        style={{
          // height: '100vh',
          padding: 32,
          textAlign: 'left',
        }}
      >
        <Row>
          <Col span={20}>
            <Paragraph className={classes.text}>Investment: $27100</Paragraph>
            <Paragraph className={classes.text}>
              Portfolio: $
              {(
                ada['balance'] +
                shib['balance'] +
                bezoge['balance'] +
                quid['balance'] +
                peeps['balance'] +
                mintys['balance']
              ).toFixed(2)}
            </Paragraph>
            <Paragraph className={classes.text}>
              Difference:
              {renderDifference(
                ada['balance'] +
                  shib['balance'] +
                  bezoge['balance'] +
                  quid['balance'] +
                  peeps['balance'] +
                  mintys['balance'] -
                  27100
              )}
            </Paragraph>
          </Col>
          <Col span={4}>
            <Progress
              type="circle"
              percent={secPercent[seconds]}
              format={(percent) => (
                <Text className={classes.ptext}>{seconds}</Text>
              )}
              width={32}
              strokeWidth={16}
              // strokeColor={'green'}
            />
          </Col>
        </Row>

        <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={
                <div className={classes.cardTitle}>
                  <img src={cardano} style={{ maxHeight: 24 }} />
                  <Text className={classes.ctext}>Cardano</Text>
                </div>
              }
              bordered={false}
            >
              <Paragraph level={4} className={classes.text}>
                Price: {ada['price']}
              </Paragraph>
              <Paragraph level={4} className={classes.text}>
                Balance: ${ada['balance'] ? ada['balance'].toFixed(2) : ''}
              </Paragraph>
            </Card>
          </Col>

          <Col span={12}>
            <Card
              title={
                <div className={classes.cardTitle}>
                  <img src={shibLogo} style={{ maxHeight: 24 }} />
                  <Text className={classes.ctext}>Shiba Inu</Text>
                </div>
              }
              bordered={false}
            >
              <Paragraph level={4} className={classes.text}>
                Price: {shib['price']}
              </Paragraph>
              <Paragraph level={4} className={classes.text}>
                Balance: ${shib['balance'] ? shib['balance'].toFixed(2) : ''}
              </Paragraph>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={
                <div className={classes.cardTitle}>
                  <img src={bezogeImage} style={{ maxHeight: 24 }} />
                  <Text className={classes.ctext}>Bezoge</Text>
                </div>
              }
              bordered={false}
            >
              <Paragraph level={4} className={classes.text}>
                Price: {bezoge['price']}
              </Paragraph>
              <Paragraph level={4} className={classes.text}>
                Balance: $
                {bezoge['balance'] ? bezoge['balance'].toFixed(2) : ''}
              </Paragraph>
            </Card>
          </Col>

          <Col span={12}>
            <Card
              title={
                <div className={classes.cardTitle}>
                  <img src={quidImage} style={{ maxHeight: 24 }} />
                  <Text className={classes.ctext}>Quid</Text>
                </div>
              }
              bordered={false}
            >
              <Paragraph level={4} className={classes.text}>
                Price: {quid['price']}
              </Paragraph>
              <Paragraph level={4} className={classes.text}>
                Balance: ${quid['balance'] ? quid['balance'].toFixed(2) : ''}
              </Paragraph>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }} gutter={[16, 16]}>
          <Col span={12}>
            <Card
              title={
                <div className={classes.cardTitle}>
                  <img src={peepsLogo} style={{ maxHeight: 24 }} />
                  <Text className={classes.ctext}>Peeps</Text>
                </div>
              }
              bordered={false}
            >
              <Paragraph level={4} className={classes.text}>
                Price: {peeps['price']}
              </Paragraph>
              <Paragraph level={4} className={classes.text}>
                Balance: ${peeps['balance'] ? peeps['balance'].toFixed(2) : ''}
              </Paragraph>
            </Card>
          </Col>

          <Col span={12}>
            <Card
              title={
                <div className={classes.cardTitle}>
                  <img src={mintysLogo} style={{ maxHeight: 24 }} />
                  <Text className={classes.ctext}>Mintys</Text>
                </div>
              }
              bordered={false}
            >
              <Paragraph level={4} className={classes.text}>
                Price: {mintys['price']}
              </Paragraph>
              <Paragraph level={4} className={classes.text}>
                Balance: $
                {mintys['balance'] ? mintys['balance'].toFixed(2) : ''}
              </Paragraph>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default Dashboard;
