import 'antd/dist/antd.css';
import './App.css';
import axios from 'axios';
import {
  Statistic,
  Card,
  Row,
  Col,
  Typography,
  Progress,
  Empty,
  AutoComplete,
  Result,
} from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { Layout } from 'antd';
import bitcoin from './assets/bitcoin.svg';
import district0x from './assets/district0x.svg';
import cardano from './assets/cardano.svg';
import dogecoin from './assets/dogecoin.svg';
import bittorrent from './assets/bittorrent.svg';
import stellar from './assets/stellar.svg';
import { makeStyles } from '@material-ui/styles';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Dashboard from './pages/dashboard';

const useStyles = makeStyles({
  text: {
    // color: '#ffffff !important',
    fontSize: '14px !important',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
  },
  ptext: {
    fontSize: '12px !important',
    // fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
  },
  ctext: {
    fontSize: '14px !important',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
    marginLeft: 8,
  },
  cardTitle: {
    display: 'flex',
    alignContent: 'center',
  },
});

function App() {
  const classes = useStyles();
  const { Title, Text, Paragraph } = Typography;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/dashboard/97795a60-80c3-4f32-9888-e3a931f58737">
          <Dashboard />
        </Route>
        <Route path="/" exact>
          <div
            style={{
              // padding: 32,
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '100vh',
            }}
          >
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              // extra={<Button type="primary">Back Home</Button>}
            />
          </div>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
